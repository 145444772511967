import { request } from '../methods'

export default {
  auth: request('post', '/api/v1/users/auth/'),
  read: request('get', '/api/v1/users/{id}/'),
  changePassword: request('post', '/api/v1/users/change_password/'),
  requestResetPassword: request('post', '/api/v1/users/request_reset_password/'),
  clear: request('delete', '/api/v1/users/clear_2fa/{device_type}/'),
  generateSMSChallenge: request('post', '/api/v1/users/generate_sms_challenge/'),
  emailChallenge: request('post', '/api/v1/users/email_challenge/'),
  fetch: request('post', '/api/v1/users/fetch/'),
  account: request('get', '/api/v1/users/account/'),
  setupAccount: request('post', '/api/v1/users/setup_account/'),
  provisionSMS: request('post', '/api/v1/users/provision_sms/'),
  resetPassword: request('post', '/api/v1/users/reset_password/'),
  validateSMSUpdate: request('post', '/api/v1/users/validate_sms_update/'),
  provisionQR: request('post', '/api/v1/users/provision_qr/'),
  devices: request('get', '/api/v1/users/{id}/devices/'),
  onboard: request('post', '/api/v1/users/onboard/'),
}
